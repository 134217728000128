/**
 * require для node_modules
 * import для наших папок
 */
window.$ = window.jQuery = require('jquery');

require('normalize.css');

require('slick-carousel');
require('slick-carousel/slick/slick.css');

require('@fortawesome/fontawesome-free/css/fontawesome.css');
require('@fortawesome/fontawesome-free/js/fontawesome.js');
require('./fontawesome/fapp.js');

require('select2/dist/css/select2.min.css');
require('select2/dist/js/select2.min.js');
require('./select2-themes/sorted.scss');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
