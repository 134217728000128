(function () {
  'use strict';

  var _WINDOW = {};
  var _DOCUMENT = {};

  try {
    if (typeof window !== 'undefined') _WINDOW = window;
    if (typeof document !== 'undefined') _DOCUMENT = document;
  } catch (e) {}

  var _ref = _WINDOW.navigator || {},
      _ref$userAgent = _ref.userAgent,
      userAgent = _ref$userAgent === void 0 ? '' : _ref$userAgent;

  var WINDOW = _WINDOW;
  var DOCUMENT = _DOCUMENT;
  var IS_BROWSER = !!WINDOW.document;
  var IS_DOM = !!DOCUMENT.documentElement && !!DOCUMENT.head && typeof DOCUMENT.addEventListener === 'function' && typeof DOCUMENT.createElement === 'function';
  var IS_IE = ~userAgent.indexOf('MSIE') || ~userAgent.indexOf('Trident/');

  var NAMESPACE_IDENTIFIER = '___FONT_AWESOME___';
  var PRODUCTION = function () {
    try {
      return undefined === 'production';
    } catch (e) {
      return false;
    }
  }();

  function bunker(fn) {
    try {
      fn();
    } catch (e) {
      if (!PRODUCTION) {
        throw e;
      }
    }
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};
      var ownKeys = Object.keys(source);

      if (typeof Object.getOwnPropertySymbols === 'function') {
        ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
          return Object.getOwnPropertyDescriptor(source, sym).enumerable;
        }));
      }

      ownKeys.forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    }

    return target;
  }

  var w = WINDOW || {};
  if (!w[NAMESPACE_IDENTIFIER]) w[NAMESPACE_IDENTIFIER] = {};
  if (!w[NAMESPACE_IDENTIFIER].styles) w[NAMESPACE_IDENTIFIER].styles = {};
  if (!w[NAMESPACE_IDENTIFIER].hooks) w[NAMESPACE_IDENTIFIER].hooks = {};
  if (!w[NAMESPACE_IDENTIFIER].shims) w[NAMESPACE_IDENTIFIER].shims = [];
  var namespace = w[NAMESPACE_IDENTIFIER];

  function defineIcons(prefix, icons) {
    var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _params$skipHooks = params.skipHooks,
        skipHooks = _params$skipHooks === void 0 ? false : _params$skipHooks;
    var normalized = Object.keys(icons).reduce(function (acc, iconName) {
      var icon = icons[iconName];
      var expanded = !!icon.icon;

      if (expanded) {
        acc[icon.iconName] = icon.icon;
      } else {
        acc[iconName] = icon;
      }

      return acc;
    }, {});

    if (typeof namespace.hooks.addPack === 'function' && !skipHooks) {
      namespace.hooks.addPack(prefix, normalized);
    } else {
      namespace.styles[prefix] = _objectSpread({}, namespace.styles[prefix] || {}, normalized);
    }

    if (prefix === 'fas') {
      defineIcons('fa', icons);
    }
  }

  var icons = {
    'cart': [16, 16, [], '000001', 'M4.8 12.8C3.92 12.8 3.2 13.52 3.2 14.4C3.2 15.28 3.92 16 4.8 16C5.68 16 6.4 15.28 6.4 14.4C6.4 13.52 5.68 12.8 4.8 12.8ZM0 0V1.6H1.6L4.48 7.68L3.36 9.6C3.28 9.84 3.2 10.16 3.2 10.4C3.2 11.28 3.92 12 4.8 12H14.4V10.4H5.12C5.04 10.4 4.96 10.32 4.96 10.24V10.16L5.68 8.79997H11.6C12.24 8.79997 12.72 8.47997 12.96 7.99997L15.84 2.8C16 2.64 16 2.56 16 2.4C16 1.92 15.68 1.6 15.2 1.6H3.36L2.64 0H0ZM12.8 12.8C11.92 12.8 11.2 13.52 11.2 14.4C11.2 15.28 11.92 16 12.8 16C13.68 16 14.4 15.28 14.4 14.4C14.4 13.52 13.68 12.8 12.8 12.8Z'],
    'rub': [12, 12, [], '000002', 'M6.6575 9.85156H4.04031V12H2.54813V9.85156H0.930938V8.61719H2.54813V7.54688H0.930938V6.32031H2.54813V0.625H6.74344C7.96219 0.625 8.92833 0.934896 9.64188 1.55469C10.3554 2.17448 10.7122 3.01302 10.7122 4.07031C10.7122 5.17969 10.3684 6.03646 9.68094 6.64062C8.99865 7.23958 8.02729 7.54167 6.76688 7.54688H4.04031V8.61719H6.6575V9.85156ZM4.04031 6.32031H6.74344C7.55073 6.32031 8.16531 6.13021 8.58719 5.75C9.00906 5.36979 9.22 4.8151 9.22 4.08594C9.22 3.42448 9.00125 2.89062 8.56375 2.48438C8.12625 2.07292 7.5351 1.86458 6.79031 1.85938H4.04031V6.32031Z'],
    'left': [14, 24, [], '000003', 'M4.13866 12L13.2122 21.0735C13.8817 21.743 13.8817 22.8284 13.2122 23.4979C12.5427 24.1674 11.4573 24.1674 10.7878 23.4979L0.502111 13.2122C-0.167358 12.5427 -0.167358 11.4573 0.502111 10.7878L10.7878 0.502103C11.4573 -0.167368 12.5427 -0.167368 13.2122 0.502103C13.8817 1.17157 13.8817 2.257 13.2122 2.92647L4.13866 12Z'],
    'right': [14, 24, [], '000004', 'M9.57563 12L0.502103 21.0735C-0.167368 21.743 -0.167368 22.8284 0.502103 23.4979C1.17157 24.1674 2.257 24.1674 2.92647 23.4979L13.2122 13.2122C13.8817 12.5427 13.8817 11.4573 13.2122 10.7878L2.92647 0.502103C2.257 -0.167368 1.17157 -0.167368 0.502103 0.502103C-0.167368 1.17157 -0.167368 2.257 0.502103 2.92647L9.57563 12Z'],
    'check': [24, 18, [], '000005', 'M8.15146 17.5456L0.351457 9.74558C-0.117152 9.27697 -0.117152 8.51718 0.351457 8.04852L2.04847 6.35146C2.51708 5.8828 3.27693 5.8828 3.74554 6.35146L8.99999 11.6059L20.2544 0.351457C20.7231 -0.117152 21.4829 -0.117152 21.9515 0.351457L23.6485 2.04852C24.1171 2.51713 24.1171 3.27693 23.6485 3.74558L9.84852 17.5456C9.37986 18.0142 8.62007 18.0142 8.15146 17.5456Z'],
    'page-double-left': [10, 8, [], '000006', 'M9.13805 7.80474C9.3984 7.54439 9.3984 7.12228 9.13805 6.86193L6.27612 4L9.13805 1.13807C9.3984 0.877722 9.3984 0.455612 9.13805 0.195262C8.8777 -0.0650873 8.45559 -0.0650873 8.19524 0.195262L4.86191 3.5286C4.60156 3.78895 4.60156 4.21105 4.86191 4.4714L8.19524 7.80474C8.45559 8.06509 8.8777 8.06509 9.13805 7.80474ZM4.47139 6.86193L1.60947 4L4.47139 1.13807C4.73174 0.877722 4.73174 0.455612 4.47139 0.195262C4.21104 -0.0650873 3.78893 -0.0650873 3.52859 0.195262L0.195251 3.5286C-0.0650978 3.78895 -0.0650978 4.21105 0.195251 4.4714L3.52859 7.80474C3.78893 8.06509 4.21104 8.06509 4.47139 7.80474C4.73174 7.54439 4.73174 7.12228 4.47139 6.86193Z'],
    'page-double-right': [10, 8, [], '000007', 'M0.195293 7.80474C-0.0650569 7.54439 -0.0650569 7.12228 0.195293 6.86193L3.05722 4L0.195293 1.13807C-0.0650569 0.877722 -0.0650569 0.455612 0.195293 0.195262C0.455642 -0.0650873 0.877752 -0.0650873 1.1381 0.195262L4.47144 3.5286C4.73178 3.78895 4.73178 4.21105 4.47144 4.4714L1.1381 7.80474C0.877752 8.06509 0.455642 8.06509 0.195293 7.80474ZM4.86195 6.86193L7.72388 4L4.86195 1.13807C4.6016 0.877722 4.6016 0.455612 4.86195 0.195262C5.1223 -0.0650873 5.54441 -0.0650873 5.80476 0.195262L9.13809 3.5286C9.39844 3.78895 9.39844 4.21105 9.13809 4.4714L5.80476 7.80474C5.54441 8.06509 5.1223 8.06509 4.86195 7.80474C4.6016 7.54439 4.6016 7.12228 4.86195 6.86193Z'],
    'page-left': [6, 8, [], '000008', 'M5.13805 6.86193C5.3984 7.12228 5.3984 7.54439 5.13805 7.80474C4.8777 8.06509 4.45559 8.06509 4.19524 7.80474L0.861908 4.4714C0.601559 4.21105 0.601559 3.78895 0.861908 3.5286L4.19524 0.195262C4.45559 -0.0650874 4.8777 -0.0650874 5.13805 0.195262C5.3984 0.455612 5.3984 0.877722 5.13805 1.13807L2.27612 4L5.13805 6.86193Z'],
    'page-right': [6, 8, [], '000009', 'M0.195293 6.86193C-0.0650569 7.12228 -0.0650569 7.54439 0.195293 7.80474C0.455642 8.06509 0.877752 8.06509 1.1381 7.80474L4.47144 4.4714C4.73178 4.21105 4.73178 3.78895 4.47144 3.5286L1.1381 0.195262C0.877752 -0.0650874 0.455642 -0.0650874 0.195293 0.195262C-0.0650569 0.455612 -0.0650569 0.877722 0.195293 1.13807L3.05722 4L0.195293 6.86193Z'],
    'search': [18, 18, [], '000010', 'M17.7193 15.5333L14.2211 12.0351C14.0632 11.8772 13.8491 11.7895 13.6246 11.7895H13.0526C14.0211 10.5509 14.5965 8.99298 14.5965 7.29825C14.5965 3.26667 11.3298 0 7.29825 0C3.26667 0 0 3.26667 0 7.29825C0 11.3298 3.26667 14.5965 7.29825 14.5965C8.99298 14.5965 10.5509 14.0211 11.7895 13.0526V13.6246C11.7895 13.8491 11.8772 14.0632 12.0351 14.2211L15.5333 17.7193C15.8632 18.0491 16.3965 18.0491 16.7228 17.7193L17.7158 16.7263C18.0456 16.3965 18.0456 15.8632 17.7193 15.5333ZM7.29823 11.7895C4.81753 11.7895 2.80701 9.78245 2.80701 7.29823C2.80701 4.81753 4.81402 2.80701 7.29823 2.80701C9.77894 2.80701 11.7895 4.81402 11.7895 7.29823C11.7895 9.77894 9.78245 11.7895 7.29823 11.7895Z'],
    'plus': [10, 10, [], '000011', 'M9.4573 4.30232H5.69766V0.542636C5.69766 0.371367 5.46509 0 4.99998 0C4.53487 0 4.3023 0.371388 4.3023 0.542636V4.30234H0.542634C0.371386 4.30232 0 4.53489 0 4.99998C0 5.46507 0.371386 5.69766 0.542634 5.69766H4.30232V9.45736C4.30232 9.62859 4.53487 10 5 10C5.46513 10 5.69768 9.62859 5.69768 9.45736V5.69766H9.45737C9.62859 5.69766 10 5.46511 10 4.99998C10 4.53485 9.62855 4.30232 9.4573 4.30232Z'],
    'minus': [491, 491, [], '000012', 'M465.167,211.613H240.21H26.69c-8.424,0-26.69,11.439-26.69,34.316s18.267,34.316,26.69,34.316h213.52h224.959      c8.421,0,26.689-11.439,26.689-34.316S473.59,211.613,465.167,211.613z'],
    'star': [23, 23, [], '000013', 'M10.2662 0.80008L7.45889 6.747L1.17796 7.70372C0.0516092 7.8744 -0.399792 9.3252 0.417029 10.1561L4.96113 14.7825L3.88637 21.3179C3.69291 22.4992 4.88375 23.384 5.88113 22.8315L11.5 19.7458L17.1189 22.8315C18.1162 23.3795 19.3071 22.4992 19.1136 21.3179L18.0389 14.7825L22.583 10.1561C23.3998 9.3252 22.9484 7.8744 21.822 7.70372L15.5411 6.747L12.7338 0.80008C12.2308 -0.259945 10.7735 -0.27342 10.2662 0.80008Z'],
    'cross': [12, 12, [], '000014', 'M7.06127 5.99988L11.7801 1.28102C12.0733 0.988097 12.0733 0.51281 11.7801 0.219883C11.4869 -0.0732943 11.0122 -0.0732943 10.719 0.219883L6.00012 4.93873L1.28102 0.219883C0.987847 -0.0732943 0.51306 -0.0732943 0.219883 0.219883C-0.0732943 0.51281 -0.0732943 0.988097 0.219883 1.28102L4.93898 5.99988L0.219883 10.7187C-0.0732943 11.0117 -0.0732943 11.4869 0.219883 11.7799C0.366471 11.9262 0.558587 11.9995 0.750453 11.9995C0.942319 11.9995 1.13444 11.9262 1.28102 11.7796L6.00012 7.06077L10.719 11.7796C10.8656 11.9262 11.0577 11.9995 11.2495 11.9995C11.4414 11.9995 11.6335 11.9262 11.7801 11.7796C12.0733 11.4867 12.0733 11.0114 11.7801 10.7185L7.06127 5.99988Z'],
  };

  bunker(function () {
    defineIcons('fapp', icons);
  });

}()); 
